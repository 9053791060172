// export const THE_GRAPH_URL = "https://api.thegraph.com/subgraphs/name/raxioma/paladin-v2";
export const THE_GRAPH_URL = "https://api.thegraph.com/subgraphs/name/jamesbwn/eeec-gv2"; // Goerli Testnet
export const EPOCH_INTERVAL = 9600;

// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 3;

export const TOKEN_DECIMALS = 9;

export const POOL_GRAPH_URLS = {
  5: "https://api.thegraph.com/subgraphs/name/pooltogether/rinkeby-v3_4_3",
  4: "https://api.thegraph.com/subgraphs/name/pooltogether/rinkeby-v3_4_3",
  1: "https://api.thegraph.com/subgraphs/name/pooltogether/pooltogether-v3_4_3",
};

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  56: {
    BUSD_ADDRESS: "0xe9e7cea3dedca5984780bafc599bd69add087d56", 
    USDT_ADDRESS: "0x55d398326f99059ff775485246999027b3197955",
    DAI_ADDRESS: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
    WAND_ADDRESS: "0x70e330A17059d2a58F3A3f5713044f11f9574C89",
    SWAND_ADDRESS: "0xD5068eb6006Ec25Dd0DDB4E2889E701f258E7223",
    STAKING_ADDRESS: "0x7eb815A5C0A67044A573eC150E8DA63d42b87276", // The new staking contract
    STAKING_HELPER_ADDRESS: "0x418243646904ae7379855cEf806C23f0DA1FE218", // Helper contract used for Staking only
    BONDINGCALC_ADDRESS: "0x10196f2e1F3296717138a2f23d5C8528eB7D7e69",
    TREASURY_ADDRESS: "0xC8AE53Bc9f3D0b6773702cC235c766F2b828F1E9",
    DAI_EEEC_ADDRESS: "0xDDB5be3C7733869cf047b6f00B064D5f7855A782",
	USDT_EEEC_ADDRESS: "0xaD42deba16f589478D68634E096F4e27F0aF9c93",

    DISTRIBUTOR_ADDRESS: "", //#
    REDEEM_HELPER_ADDRESS: "",
  },
  97: {
    BUSD_ADDRESS: "0xB2180448f8945C8Cc8AE9809E67D6bd27d8B2f2C", // duplicate
    WAND_ADDRESS: "0xC0b491daBf3709Ee5Eb79E603D73289Ca6060932",
    SWAND_ADDRESS: "0x1Fecda1dE7b6951B248C0B62CaeBD5BAbedc2084",
    STAKING_ADDRESS: "0xC5d3318C0d74a72cD7C55bdf844e24516796BaB2",
    STAKING_HELPER_ADDRESS: "0xf73f23Bb0edCf4719b12ccEa8638355BF33604A1",
    OLD_STAKING_ADDRESS: "0xb640AA9082ad720c60102489b806E665d67DCE32",
    WSOHM_ADDRESS: "0xe73384f11Bb748Aa0Bc20f7b02958DF573e6E2ad",
    OLD_SOHM_ADDRESS: "0x8Fc4167B0bdA22cb9890af2dB6cB1B818D6068AE",
    MIGRATE_ADDRESS: "0x3BA7C6346b93DA485e97ba55aec28E8eDd3e33E2",
    BONDINGCALC_ADDRESS: "0xaDBE4FA3c2fcf36412D618AfCfC519C869400CEB",
    CIRCULATING_SUPPLY_ADDRESS: "0x5b0AA7903FD2EaA16F1462879B71c3cE2cFfE868",
    TREASURY_ADDRESS: "0x0d722D813601E48b7DAcb2DF9bae282cFd98c6E7",
    REDEEM_HELPER_ADDRESS: "0xBd35d8b2FDc2b720842DB372f5E419d39B24781f",
    DISTRIBUTOR_ADDRESS: "0x0626D5aD2a230E05Fb94DF035Abbd97F2f839C3a",
    PT_TOKEN_ADDRESS: "0x0a2d026bacc573a8b5a2b049f956bdf8e5256cfd", // 33T token address, taken from `ticket` function on PRIZE_STRATEGY_ADDRESS
    PT_PRIZE_POOL_ADDRESS: "0xf9081132864ed5e4980CFae83bDB122d86619281", // NEW
    PT_PRIZE_STRATEGY_ADDRESS: "0x2Df17EA8D6B68Ec444c9a698315AfB36425dac8b", // NEW
  },
  5: {
    DAI_ADDRESS: "0xdc31Ee1784292379Fbb2964b3B9C4124D8F89C60", // duplicate
    WAND_ADDRESS: "0xF1C7F73918e85CB78471f53Db2effe17E6F48A89",
    SWAND_ADDRESS: "0x29bAc8c06cCF8ebAD1b15c50e0B64052782000c2",
    STAKING_ADDRESS: "0x791dc6F97fb9a45eFa64FF3e8975c0Bf49084c9C", // The new staking contract
    STAKING_HELPER_ADDRESS: "0x88a789637a1c4fC54550f304F4BC6a92a9BE7914", // Helper contract used for Staking only
    BONDINGCALC_ADDRESS: "0x52E36f2C5DeFc3787Cca0918dda7300Db80de668",
    TREASURY_ADDRESS: "0x8E9A9c1423dA4Da8078bF85Aa625f19A06b2AAB3",
    DAI_EEEC_ADDRESS: "0x0a7E15a664DAB98F79057546dE73BFD9A7f44C16",

    DISTRIBUTOR_ADDRESS: "0x59D9CF509366C750A2123B47FfA8EB4504738649", //#
    REDEEM_HELPER_ADDRESS: "",
  },
};
