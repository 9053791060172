import { StableBond, LPBond, NetworkID, CustomBond, BondType } from "src/lib/Bond";
import { addresses } from "src/constants";

import { ReactComponent as UsdcImag } from "src/assets/tokens/USDC.svg";
import { ReactComponent as DaiImag } from "src/assets/tokens/DAI.svg";
import { ReactComponent as UsdtImg } from "src/assets/tokens/USDT.svg";
import { ReactComponent as OhmDaiImg } from "src/assets/tokens/OHM-DAI.svg";
import { ReactComponent as MTNBUSDImg } from "src/assets/tokens/MTN-BUSD.svg";
import { ReactComponent as MTNBNBImg } from "src/assets/tokens/MTN-BNB.svg";
import { ReactComponent as FraxImg } from "src/assets/tokens/FRAX.svg";
import { ReactComponent as OhmFraxImg } from "src/assets/tokens/OHM-FRAX.svg";
import { ReactComponent as OhmLusdImg } from "src/assets/tokens/OHM-LUSD.svg";
import { ReactComponent as OhmEthImg } from "src/assets/tokens/OHM-WETH.svg";
import { ReactComponent as wETHImg } from "src/assets/tokens/wETH.svg";
import { ReactComponent as LusdImg } from "src/assets/tokens/LUSD.svg";
import { ReactComponent as UsdcImg } from "src/assets/tokens/USDC.svg"; 

import { ReactComponent as BUSDImg } from "src/assets/tokens/BUSD.svg";
import { ReactComponent as HecUsdcImg } from "src/assets/tokens/MTN-USDC.svg";

import { abi as FraxOhmBondContract } from "src/abi/bonds/OhmFraxContract.json";
import { abi as WandBusdContract44 } from "src/abi/bonds/WandBusdContract44.json";
import { abi as WandBusdContract } from "src/abi/bonds/WandBusdContract.json";
import { abi as BondwBNBContract } from "src/abi/bonds/wBNBContract.json";

import { abi as HecUsdcContract } from "src/abi/bonds/HecUsdcContract.json";
import { abi as BondOhmLusdContract } from "src/abi/bonds/OhmLusdContract.json";
import { abi as BondOhmEthContract } from "src/abi/bonds/OhmEthContract.json";

import { abi as BusdBondContract44 } from "src/abi/bonds/BusdContract44.json";
import { abi as BusdBondContract } from "src/abi/bonds/BusdContract.json";
import { abi as ReserveOhmLusdContract } from "src/abi/reserves/OhmLusd.json";
import { abi as ReserveOhmDaiContract } from "src/abi/reserves/OhmDai.json";
import { abi as ReserveHecUsdcContract } from "src/abi/reserves/HecUsdc.json";
import { abi as ReserveMtnBusdContract } from "src/abi/reserves/MtnBusd.json";
import { abi as ReserveOhmFraxContract } from "src/abi/reserves/OhmFrax.json";
import { abi as ReserveOhmEthContract } from "src/abi/reserves/OhmEth.json";

import { abi as CakeBondContract } from "src/abi/bonds/CakeBondContract.json";
import { abi as UsdtBondContract } from "src/abi/bonds/UsdtContract.json";
import { abi as LusdBondContract } from "src/abi/bonds/LusdContract.json";
import { abi as EthBondContract } from "src/abi/bonds/EthContract.json";

import { abi as ierc20Abi } from "src/abi/IERC20.json";
import { getBondCalculator } from "src/helpers/BondCalculator";

// TODO(zx): Further modularize by splitting up reserveAssets into vendor token definitions
//   and include that in the definition of a bond

// export const busd = new StableBond({
//   name: "busd",
//   displayName: "BUSD",
//   bondToken: "BUSD",
//   bondIconSvg: BUSDImg,
//   bondContractABI: BusdBondContract,
//   networkAddrs: {
//     [NetworkID.Mainnet]: {
//       bondAddress: "0x84762039Afaf140CaFBd9898EEB09482c0862b85",
//       reserveAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
//     },
//     [NetworkID.Testnet]: {
//       bondAddress: "0xF651283543fB9D61A91f318b78385d187D300738",
//       reserveAddress: "0x2F7249cb599139e560f0c81c269Ab9b04799E453",
//     },
//   },
// });

// export const vir = new StableBond({
//   name: "vir",
//   displayName: "VIR",
//   bondToken: "VIR",
//   bondIconSvg: BUSDImg,
//   bondContractABI: BusdBondContract,
//   networkAddrs: {
//     [NetworkID.Mainnet]: {
//       bondAddress: "0xb4117B2f079bEd59fbcB07D3ABD4d75bdaB9CFcb",
//       reserveAddress: "0xA791D063E2A137092fcCc762D2F34A28DAD3Fbc2",
//     },
//     [NetworkID.Testnet]: {
//       bondAddress: "0xF651283543fB9D61A91f318b78385d187D300738",
//       reserveAddress: "0x2F7249cb599139e560f0c81c269Ab9b04799E453",
//     },
//   },
// });

// export const usdc = new StableBond({
//   name: "usdc",
//   displayName: "USDC",
//   bondToken: "USDC",
//   bondIconSvg: UsdcImag,
//   bondContractABI: BusdBondContract,
//   networkAddrs: {
//     [NetworkID.Mainnet]: {
//       bondAddress: "0x807a2F11B92FB167716603685b2C7104eDD56244",
//       reserveAddress: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
//     },
//     [NetworkID.Testnet]: {
//       bondAddress: "0xF651283543fB9D61A91f318b78385d187D300738",
//       reserveAddress: "0x2F7249cb599139e560f0c81c269Ab9b04799E453",
//     },
//   },
// });

export const dai = new StableBond({
  name: "dai",
  displayName: "DAI",
  bondToken: "DAI",
  bondIconSvg: DaiImag,
  bondContractABI: BusdBondContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x188fa5aBEE05410196253D85338A0bD5C62DF89e",
      reserveAddress: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",

      // Goerli Testnet
      // bondAddress: "0x1D5fD30981cF96352ea1d4cd9611Aa03D7C44f5f",
      // reserveAddress: "0xdc31Ee1784292379Fbb2964b3B9C4124D8F89C60",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0xF651283543fB9D61A91f318b78385d187D300738",
      reserveAddress: "0x2F7249cb599139e560f0c81c269Ab9b04799E453",
    },
  },
});

export const usdt = new StableBond({
  name: "usdt",
  displayName: "USDT",
  bondToken: "USDT",
  bondIconSvg: UsdtImg,
  bondContractABI: BusdBondContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x2172058fAb0Ec0D4fcE6Ca02dA9B2F84284b481c",
      reserveAddress: "0x55d398326f99059fF775485246999027B3197955",

      // Goerli Testnet
      // bondAddress: "0x1D5fD30981cF96352ea1d4cd9611Aa03D7C44f5f",
      // reserveAddress: "0xdc31Ee1784292379Fbb2964b3B9C4124D8F89C60",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0xF651283543fB9D61A91f318b78385d187D300738",
      reserveAddress: "0x2F7249cb599139e560f0c81c269Ab9b04799E453",
    },
  },
});

///--- 4.4 BUSD Bond
// export const busd44 = new StableBond({
//   name: "busd44",
//   displayName: "BUSD(4, 4)",
//   bondToken: "BUSD",
//   bondIconSvg: BUSDImg,
//   bondContractABI: BusdBondContract44,
//   isFour: true,
//   isTotal: true,
//   networkAddrs: {
//     [NetworkID.Mainnet]: {
//       bondAddress: "0x98b10f741e0EBb214cf33dD72714b083aA0240D9",
//       reserveAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
//     },
//     [NetworkID.Testnet]: {
//       bondAddress: "0xF651283543fB9D61A91f318b78385d187D300738",
//       reserveAddress: "0x2F7249cb599139e560f0c81c269Ab9b04799E453",
//     },
//   },
// });

///--- 4.4 BUSD Bond
// export const usdc44 = new StableBond({
//   name: "usdc44",
//   displayName: "USDC(4, 4)",
//   bondToken: "USDC",
//   bondIconSvg: UsdcImag,
//   isFour: true,
//   isTotal: true,
//   bondContractABI: BusdBondContract44,
//   networkAddrs: {
//     [NetworkID.Mainnet]: {
//       bondAddress: "0xbd7017BfFd7142b768c1D38B5BF6084F6b6eF6A4",
//       reserveAddress: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
//     },
//     [NetworkID.Testnet]: {
//       bondAddress: "0xF651283543fB9D61A91f318b78385d187D300738",
//       reserveAddress: "0x2F7249cb599139e560f0c81c269Ab9b04799E453",
//     },
//   },
// });

///--- 4.4 BUSD Bond
// export const dai44 = new StableBond({
//   name: "dai44",
//   displayName: "DAI(4, 4)",
//   bondToken: "DAI",
//   bondIconSvg: DaiImag,
//   bondContractABI: BusdBondContract44,
//   isFour: true,
//   isTotal: true,
//   networkAddrs: {
//     [NetworkID.Mainnet]: {
//       bondAddress: "0x89D45c9fECf53345beeAF792B825C1dC20d00760",
//       reserveAddress: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
//     },
//     [NetworkID.Testnet]: {
//       bondAddress: "0xF651283543fB9D61A91f318b78385d187D300738",
//       reserveAddress: "0x2F7249cb599139e560f0c81c269Ab9b04799E453",
//     },
//   },
// });


// export const usdt = new StableBond({
//   name: "usdt",
//   displayName: "USDT",
//   bondToken: "USDT",
//   bondIconSvg: UsdtImg,
//   bondContractABI: UsdtBondContract,
//   networkAddrs: {
//     [NetworkID.Mainnet]: {
//       bondAddress: "0xC3533063117b0d68C8b6b1B2D6f307eb8426d0d5",    // USDT Bond Depository address
//       reserveAddress: "0x55d398326f99059ff775485246999027b3197955", // USDT address
//     },
//     [NetworkID.Testnet]: {
//       bondAddress: "0xDea5668E815dAF058e3ecB30F645b04ad26374Cf",
//       reserveAddress: "0xB2180448f8945C8Cc8AE9809E67D6bd27d8B2f2C",
//     },
//   },
// });

export const eeec_dai = new LPBond({
  name: "eeec_dai_lp",
  displayName: "EEEC-DAI LP",
  bondToken: "DAI",
  bondIconSvg: OhmDaiImg,
  bondContractABI: WandBusdContract,
  reserveContract: ReserveMtnBusdContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x5D4b3f6eA402fA3b8F32bE55C89fd5aE8b0C12E4",
      reserveAddress: "0xDDB5be3C7733869cf047b6f00B064D5f7855A782",

      // Goerli Testnet
      // bondAddress: "0xE0464D420D423c3fE8c6c899c576c90C4AC65252",
      // reserveAddress: "0x0a7E15a664DAB98F79057546dE73BFD9A7f44C16",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0xcF449dA417cC36009a1C6FbA78918c31594B9377",
      reserveAddress: "0x8D5a22Fb6A1840da602E56D1a260E56770e0bCE2",
    },
  },
  lpUrl:
    "",
});

export const eeec_usdt = new LPBond({
  name: "eeec_usdt_lp",
  displayName: "EEEC-USDT LP",
  bondToken: "DAI",
  bondIconSvg: MTNBUSDImg,
  bondContractABI: WandBusdContract,
  reserveContract: ReserveMtnBusdContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0xaD42deba16f589478D68634E096F4e27F0aF9c93",
      reserveAddress: "0x70920769487dc26fF47730Df7BBbca60c3E96345",

      // Goerli Testnet
      // bondAddress: "0xE0464D420D423c3fE8c6c899c576c90C4AC65252",
      // reserveAddress: "0x0a7E15a664DAB98F79057546dE73BFD9A7f44C16",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0xcF449dA417cC36009a1C6FbA78918c31594B9377",
      reserveAddress: "0x8D5a22Fb6A1840da602E56D1a260E56770e0bCE2",
    },
  },
  lpUrl:
    "",
});



// HOW TO ADD A NEW BOND:
// Is it a stableCoin bond? use `new StableBond`
// Is it an LP Bond? use `new LPBond`
// Add new bonds to this array!!
export const allBonds = [dai, eeec_dai, usdt, eeec_usdt];//mtn_wbnb, cake
export const allBondsMap = allBonds.reduce((prevVal, bond) => {
  return { ...prevVal, [bond.name]: bond };
}, {});

// Debug Log
// console.log(allBondsMap);
export default allBonds;

